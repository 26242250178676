import './Title.scss';
import scrollRevealAnimation from '../../../hooks/scrollRevealAnimation';
function Title(props) {

    const subtitle = props.subtitle;
    const title = props.title;
    const modeType = props.modeType;
    const customClass = props.customClass;

    window.addEventListener("scroll", scrollRevealAnimation);

    return (
        <div className={`title ${modeType} `}>
            <p>{subtitle}</p>
            <h2>{title}</h2>
        </div>
    )
}

export default Title;