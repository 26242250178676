import Title from '../../../../common/title/Title';
import './Newsletter.scss';
function Newsletter() {
    return (
        <div className='newsletter-block wrapper'>
            {/* <img src={Ellipse3}/> */}
            <div className='newsletter-block-text'>
                <Title subtitle='Our Newsletter' title='Subscribe To Our Newsletter to Get More Updates' modeType='light' />
                <form>
                    <input type="email" name="email" placeholder="Enter you email address" />
                    <button type="submit">Get Free Trial<i className="fa-solid fa-arrow-right"></i></button>
                </form>
            </div>
            <div className='newsletter-block-img'>
                {/* <img className='' src='https://wordpressriverthemes.com/htmltemp/sland/assets/images/newsletter/newsletter-two.png' /> */}
            </div>


        </div >
    )
}
export default Newsletter;