export default function scrollRevealAnimation() {

    var reveals = document.querySelectorAll(`.reveal`);

    let windowHeight;
    let elementTop;
    let elementVisible = 100;

    reveals.forEach(reveal => {
        windowHeight = window.innerHeight;
        elementTop = reveal.getBoundingClientRect().top;
        // console.log(elementTop);
        if (elementTop < windowHeight - elementVisible) {
            reveal.classList.add("active");
        } else {
            reveal.classList.remove("active");
        }
    });

}

