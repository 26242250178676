import './ContactBlock.scss';
import scrollRevealAnimation from '../../../../../../hooks/scrollRevealAnimation';

function ContactBlock(props) {

    window.addEventListener("scroll", scrollRevealAnimation);

    return (
        <div className='contactBlock reveal reveal-from-left'>
            <div className='contactBlock-ico'>
                {props.children}
            </div>
            <div className='contactBlock-info'>
                <h4>{props.title}</h4>
                <a href={props.value} target='_blank'>{props.text}</a>
            </div>
        </div>
    )
}
export default ContactBlock;