import { useState } from 'react';
import './Card.scss';
import scrollRevealAnimation from '../../../hooks/scrollRevealAnimation';

function Card(props) {

    const ico = props.ico;
    const title = props.title;
    const desc = props.desc;
    const [hover, setHover] = useState('card-ico-block--out');

    window.addEventListener("scroll", scrollRevealAnimation);

    return (
        <div className='card reveal'>
            <div className={`card-ico-block ${hover}`}>
                <div className={`card-ico ${ico}`}>
                    <props.ico className='card-ico-svg'/>
                </div>

                <div className='card-border'></div>
            </div>
            <h4>{props.title}</h4>
            <p>{props.desc}</p>
            {/* <a href='#' 
                onMouseOver={() => setHover('card-ico-block--over')} 
                onMouseOut={() => setHover('card-ico-block--out')}>
                    <span>Learn More</span>
                    <i className="fa-solid fa-arrow-right"></i>
            </a> */}
        </div>
    )
}

export default Card;