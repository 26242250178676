import Title from '../../../../common/title/Title';
import WhyCard from './why-card/WhyCard';
import './Why.scss';
import scrollRevealAnimation from '../../../../../hooks/scrollRevealAnimation';
import whyData from '../../../../../assets/data/whyCards.json';
import { HiDocumentReport } from 'react-icons/hi';
import { TbSettingsAutomation } from 'react-icons/tb';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { TbTruckDelivery } from 'react-icons/tb';
import { VscTools } from 'react-icons/vsc';
import { BsFillCartCheckFill } from 'react-icons/bs';
import { LangContext } from '../../../../../LangContext';
import { useContext } from 'react';
import en from '../../../../../assets/data/en/why.json';
import sr from '../../../../../assets/data/sr/why.json';

function Why() {

    const lang = useContext(LangContext);
    const icons = [VscTools, FaEnvelopeOpenText, TbTruckDelivery, BsFillCartCheckFill, TbSettingsAutomation, HiDocumentReport]
    window.addEventListener("scroll", scrollRevealAnimation);
    let whyData = [];

    lang[0] === 'en' ? whyData = en[1].whyCard : whyData = sr[1].whyCard;

    const whyDataJsx = whyData.map(function (card, i) {
        const icon = icons[i];
        return (
            <WhyCard
                title={card.title}
                desc={card.desc}
                key={i}
                ico={icon}
            />
        );
    });

    return (
        <div className='why' id='why-choose-krug'>

            <div className='wrapper'>
                <Title subtitle={lang[0] === 'en' ? en[0].header.subtitle : sr[0].header.subtitle} title={lang[0] === 'en' ? en[0].header.title : sr[0].header.title} modeType='light' customClass='reveal-from-left' />
                <div className='why-cards'>
                    {whyDataJsx}
                </div>
            </div>
        </div>
    );
}

export default Why;