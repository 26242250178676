import './Success.scss';
import { RiMailCheckLine } from 'react-icons/ri';

function Success(props) {

    return (
        <div className={`email-sent ${props.emailStatusMessage ? 'email-sent--show' : 'email-sent--hide'}`}>
            <RiMailCheckLine />
            <p>Email has been sent successfully</p>
        </div>
    )
}

export default Success;