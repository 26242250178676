
import './Header.scss';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import HeaderImg1 from '../../assets/images/dash1.webp';
import Nav from '../nav/Nav';
import Success from '../common/success/Success';
import { LangContext } from '../../LangContext';
import { useContext } from 'react';
import sr from '../../assets/data/sr/header.json';
import en from '../../assets/data/en/header.json';

function Header() {

    const lang = useContext(LangContext);
    let [opacityContent, setOpacityContent] = useState('header-transitions-loadpage--add');
    let [emailStatus, setEmailStatus] = useState(false);
    let [emailStatusMessage, setEmailStatusMessage] = useState(false);

    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const onSubmit = (data) => {
        var xhr = new XMLHttpRequest();

        //get a callback when the server responds
        xhr.addEventListener('load', () => {
            setEmailStatus(true);
            console.log(xhr.responseText);
        });

        // xhr.open('POST', 'https://krug-is.rs/test/sendemail/index.php?sendto=' + data.email + '&name=' + data.name + '&message=' + data.message);
        xhr.open('POST',
            'https://www.krug-is.rs/sendemail/trial.php?sendto=' + data.email);
        //send the request
        xhr.send();
        reset();
        data.email = null;
    }

    useEffect(() => {

        let opacityContentTimeout = setTimeout(() => {
            setOpacityContent('header-transittions--loadpage--remove');
        }, 0)

        if (emailStatus) {
            setEmailStatusMessage(true);
            setTimeout(() => {
                setEmailStatusMessage(false);
            }, 1500)
        }
        setTimeout(() => {
            setEmailStatus(false)
        }, 1500);

        return () => {
            clearTimeout(opacityContentTimeout);
        }

    }, [emailStatus, lang]);

    return (
        <div className={`header ${opacityContent}`} id='home'>

            <Nav />
            <Success emailStatusMessage={emailStatusMessage} />
            <div className="header-block">
                <div className="wrapper header-wrapper">
                    <div className="header-desc">
                        <h1>{lang[0] === 'en' ? en.title : sr.title}</h1>
                        <p>{lang[0] === 'en' ? en.text : sr.text}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-group'>
                                <input type="text" name="email" placeholder={lang[0] === 'en' ? en.form.placeholder : sr.form.placeholder}
                                    {...register('email', {
                                        required: {
                                            value: true,
                                            message: `${lang[0] === 'en' ? en.form.errors[0].text : sr.form.errors[0].text}`
                                        },
                                        pattern: {
                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                            message: `${lang[0] === 'en' ? en.form.errors[1].text : sr.form.errors[1].text}`
                                        }
                                    })} />

                                <button type="submit">{lang[0] === 'en' ? en.form.btn : sr.form.btn}<i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                            <p className='err'><ErrorMessage errors={errors} name="email">
                                {({ messages }) => {
                                    console.log(messages);
                                    return (
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message.message}</p>
                                        ))
                                    );
                                }}
                            </ErrorMessage></p>


                        </form>
                    </div>
                    <div className="header-image">
                        <img src={HeaderImg1} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Header;