import './Animation.scss';
import LogoPart1 from '../../assets/images/LogoPart1.svg';
import LogoPart2 from '../../assets/images/LogoPart2.svg';
import KSVG from '../../assets/images/k.svg';
import RSVG from '../../assets/images/R.svg';
import USVG from '../../assets/images/U.svg';
import GSVG from '../../assets/images/G.svg';
import { Fragment } from 'react';
function Animation() {

    return (
        <Fragment>
            <div className='overlay'></div>
            <div className='animation'>

                <div className='logo'>
                    <div className='logo-img'>
                        <img src={LogoPart1} className='logoPart1' />
                        <img src={LogoPart2} className='logoPart2' />
                    </div>
                    <div className='logo-text'>
                        <img src={KSVG} className='k letter' />
                        <img src={RSVG} className='r letter' />
                        <img src={USVG} className='u letter' />
                        <img src={GSVG} className='g letter' />
                    </div>
                </div>

            </div>
        </Fragment>

    )

}
export default Animation;