
import './Main.scss';

import Solutions from "./solutions/Solutions";
import Descriptions from './descriptions/Descriptions';
import Why from './why/Why';
import Newsletter from './newsletter/Newsletter';
import Screenshots from './screenshots/Screenshots';
import Faq from './faq/Faq';
import Contact from './contact/Contact';
function Main() {
    return (
        <div className="main">
            <Why />
            <Descriptions />
            <div className='newsletter'>
                <Newsletter />
            </div>
            <Solutions />
            <Screenshots />
            <Faq />
            <Contact />
        </div>
    )
}
export default Main;