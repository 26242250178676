import "./Faq.scss";
import Question from '../../../../common/question/Question';
import Title from '../../../../common/title/Title';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import scrollRevealAnimation from "../../../../../hooks/scrollRevealAnimation";
import faqData from '../../../../../assets/data/faq.json';
import en from '../../../../../assets/data/en/faq.json';
import sr from '../../../../../assets/data/sr/faq.json';
import { LangContext } from '../../../../../LangContext';
import { useContext } from 'react';

function Faq() {

    let lang = useContext(LangContext);
    window.addEventListener("scroll", scrollRevealAnimation);
    let faqData = [];
    lang[0] === 'en' ? faqData = en[1].faqData: faqData = sr[1].faqData;

    const responsive = {
        desktop3: {
            breakpoint: { max: 4000, min: 2560 },
            items: 4
        },
        desktop2: {
            breakpoint: { max: 2560, min: 1920 },
            items: 4
        },

        desktop1: {
            breakpoint: { max: 1920, min: 1440 },
            items: 3
        },
        bigTablet: {
            breakpoint: { max: 1440, min: 768 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1
        },

        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const faqDataJson = faqData.map(function(faqItem,i) {
        return (
            <Question key={faqItem.id} num={faqItem.id} question={faqItem.question} answer={faqItem.answer}/>
        )
    })

    return (
        <div className="faq" id="faq">
            <div className="faq-desc">
                <Title
                    subtitle={lang[0] === 'en' ? en[0].header.subtitle : sr[0].header.subtitle}
                    title={lang[0] === 'en' ? en[0].header.title : sr[0].header.title}
                    modeType='light'
                />
                <p className="faq-desc-text">{lang[0] === 'en' ? en[0].header.text : sr[0].header.text}</p>
            </div>
            <div className="faq-text reveal">
                <Carousel
                    responsive={responsive}
                    containerClass="carousel-container"
                    itemClass="carousel-item" swipeable={true} showDots={true} infinite={true}
                    removeArrowOnDeviceType={["mobile", "tablet", 'bigTablet', 'desktop1', 'desktop2', 'desktop3']}
                    autoPlay={true}
                    autoPlaySpeed={3500}
                    customTransition="all .7s ease"
                    transitionDuration={700}
                    keyBoardControl={true}
                >
                    {faqDataJson}
                </Carousel>
            </div>
        </div>
    )
}
export default Faq;