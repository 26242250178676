import Description from './description/Description';
import './Descriptions.scss';
import descriptionsData from '../../../../../assets/data/description.json';
import en from '../../../../../assets/data/en/description.json';
import sr from '../../../../../assets/data/sr/description.json';
import { LangContext } from '../../../../../LangContext';
import { useContext } from 'react';

function Descriptions() {

    let descriptionsData = [];
    let lang = useContext(LangContext);

    lang[0] === 'en' ? descriptionsData = en : descriptionsData = sr;

    const descriptionsDataJsx = descriptionsData.map(function(description,i) {
        return (
            <Description 
                flexType={description.flexType}
                revealFromImg={description.revealFromImg} 
                revealFromDesc={description.revealFromDesc}
                title={description.title}
                subtitle={description.subtitle}
                desc={description.desc}
                list={description.list}
                key={i}
            />
        )
    })

    return (
        <div className='descriptions' id='dashboard-specification'>
            <div className='wrapper'>
                {descriptionsDataJsx}
            </div>
        </div>
    )
}
export default Descriptions;