import './Solutions.scss';
import Title from '../../../../common/title/Title';
import Card from '../../../../common/card/Card';
import solutionsData from '../../../../../assets/data/solutions.json';
import {BiServer} from 'react-icons/bi';
import {AiOutlineCloud} from 'react-icons/ai';
import en from '../../../../../assets/data/en/solutions.json';
import sr from '../../../../../assets/data/sr/solutions.json';
import { LangContext } from '../../../../../LangContext';
import { useContext } from 'react';

function Solutions() {

    let lang = useContext(LangContext);
    const icons = [BiServer,AiOutlineCloud];
    let solutionsData = [];

    lang[0] === 'en' ? solutionsData = en[1].solutionsData : solutionsData = sr[1].solutionsData;

    const solutionsDataJsx = solutionsData.map(function (card, i) {
        const ico = icons[i];
        return (
            <Card
                ico={ico}
                title={card.title}
                desc={card.desc}
                key={i}
            />
        );
    });
    return (
        <div className="solutions" id='packages'>
            <div className="wrapper solutions-wrapper">
                <Title subtitle={lang[0] === 'en' ? en[0].header.subtitle : sr[0].header.subtitle} title={lang[0] === 'en' ? en[0].header.title : sr[0].header.title} modeType='dark' />
                <div className='cards'>
                    {solutionsDataJsx}
                </div>
            </div>
        </div>
    )
}

export default Solutions;