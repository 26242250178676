import './NotFound.scss';

function NotFound(){
    return(
        <div className='notFound'>
            <h1>404</h1>
        </div>
    )
}

export default NotFound;
