import './Contact.scss';
import Title from '../../../../common/title/Title';
import ContactBlock from './contact-block/ContactBlock';
import scrollRevealAnimation from '../../../../../hooks/scrollRevealAnimation';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { Fragment, useEffect, useState } from 'react';
import Success from '../../../../common/success/Success';
import contactData from '../../../../../assets/data/contact.json';
import { MdOutlineEmail } from 'react-icons/md';
import { BsTelephone } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import en from '../../../../../assets/data/en/contact.json';
import sr from '../../../../../assets/data/sr/contact.json';
import { LangContext } from '../../../../../LangContext';
import { useContext } from 'react';

function Contact() {

   
    window.addEventListener("scroll", scrollRevealAnimation);

    let [emailStatus, setEmailStatus] = useState(false);
    let [emailStatusMessage, setEmailStatusMessage] = useState(false);
    let lang = useContext(LangContext);

    lang[0] === 'en' ? contactData = en[1].contactData : contactData = sr[1].contactData;

    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const onSubmit = (data) => {

        var xhr = new XMLHttpRequest();

        //get a callback when the server responds

        xhr.addEventListener('load', () => {
            setEmailStatus(true);
            console.log(xhr.responseText);
        });

        // xhr.open('POST', 'https://krug-is.rs/test/sendemail/index.php?sendto=' + data.email + '&name=' + data.name + '&message=' + data.message);
        xhr.open('POST',
            'https://www.krug-is.rs/sendemail/index.php?sendto=' + data.email +
            '&name=' + data.name +
            '&message=' + data.message +
            '&subject=' + data.subject +
            '&company=' + data.company);
        //send the request
        xhr.send();
        reset();
    }
    const icons = [MdOutlineEmail, BsTelephone, GoLocation];
    // const icons = [MdAlternateEmail];

    const contactDataJsx = contactData.map(function (contactItem, i) {
        const CustomIcoContactBlock = icons[i];
        return (
            <ContactBlock title={contactItem.title} text={contactItem.text} value={contactItem.val} key={i}>
                <CustomIcoContactBlock />
            </ContactBlock>
        )
    });

    useEffect(() => {
        if (emailStatus) {
            setEmailStatusMessage(true);
            setTimeout(() => {
                setEmailStatusMessage(false);
            }, 1500)
        }
        setTimeout(() => {
            setEmailStatus(false)
        }, 1500);
    }, [emailStatus]);

    return (
        <div className='contact' id='contact'>

            <Success emailStatusMessage={emailStatusMessage} />
            <div className='wrapper'>
                <Title subtitle={lang[0] === 'en' ? en[0].header.subtitle : sr[0].header.subtitle} title={lang[0] === 'en' ? en[0].header.title : sr[0].header.title} modeType='dark' />
                <div className='contact-layout'>
                    <div className='contact-blocks'>
                        {contactDataJsx}
                    </div>
                    <div className='contact-form'>

                        <form className='reveal reveal-from-right' onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-group'>
                                <label htmlFor="name">{lang[0] === 'en' ? en[2].forma[0].label : sr[2].forma[0].label}</label>
                                <input type='text' name='name' {...register('name', {
                                    required: {
                                        value: true,
                                        message: `${lang[0] === 'en' ? en[2].forma[0].error[0].text : sr[2].forma[0].error[0].text}`
                                    },
                                    minLength: {
                                        value: 3,
                                        message: `${lang[0] === 'en' ? en[2].forma[0].error[1].text : sr[2].forma[0].error[1].text}`
                                    },
                                    maxLength: {
                                        value: 60,
                                        message: `${lang[0] === 'en' ? en[2].forma[0].error[2].text : sr[2].forma[0].error[2].text}`
                                    }
                                })} />
                                <p className='err'><ErrorMessage errors={errors} name="name">
                                    {({ messages }) => {
                                        return (
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message.message}</p>
                                            ))
                                        );
                                    }}
                                </ErrorMessage></p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="email">{lang[0] === 'en' ? en[2].forma[1].label : sr[2].forma[1].label}</label>
                                <input type='text' name='email'  {...register('email', {
                                    required: {
                                        value: true,
                                        message:  `${lang[0] === 'en' ? en[2].forma[1].error[0].text : sr[2].forma[1].error[0].text}`
                                    },
                                    pattern: {
                                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                        message:  `${lang[0] === 'en' ? en[2].forma[1].error[1].text : sr[2].forma[1].error[1].text}`
                                    }
                                })} />
                                <p className='err'><ErrorMessage errors={errors} name="email" >
                                    {({ messages }) => {
                                        console.log(messages);
                                        return (
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message.message}</p>
                                            ))
                                        );
                                    }}
                                </ErrorMessage></p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="company">{lang[0] === 'en' ? en[2].forma[2].label : sr[2].forma[2].label}</label>
                                <input type='text' name='company' {...register('company', {
                                    required: {
                                        value: true,
                                        message:  `${lang[0] === 'en' ? en[2].forma[2].error[0].text : sr[2].forma[2].error[0].text}`
                                    }
                                })} />
                                <p className='err'><ErrorMessage errors={errors} name="company" >
                                    {({ messages }) => {
                                    
                                        return (
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message.message}</p>
                                            ))
                                        );
                                    }}
                                </ErrorMessage></p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="subject">{lang[0] === 'en' ? en[2].forma[3].label : sr[2].forma[3].label}</label>
                                <input type='text' name='subject' {...register('subject', {
                                    required: {
                                        value: true,
                                        message: `${lang[0] === 'en' ? en[2].forma[3].error[0].text : sr[2].forma[3].error[0].text}`
                                    }
                                })} />
                                <p className='err'><ErrorMessage errors={errors} name="subject" >
                                    {({ messages }) => {
                                        console.log(messages);
                                        return (
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message.message}</p>
                                            ))
                                        );
                                    }}
                                </ErrorMessage></p>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="message" >{lang[0] === 'en' ? en[2].forma[4].label : sr[2].forma[4].label}</label>
                                <textarea name='message' {...register('message', {
                                    required: {
                                        value: true,
                                        message:  `${lang[0] === 'en' ? en[2].forma[4].error[0].text : sr[2].forma[4].error[0].text}`
                                    },
                                    minLength: {
                                        value: 10,
                                        message: `${lang[0] === 'en' ? en[2].forma[4].error[1].text : sr[2].forma[4].error[1].text}`
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: `${lang[0] === 'en' ? en[2].forma[4].error[2].text : sr[2].forma[4].error[2].text}`
                                    }
                                })}></textarea>
                                <p className='err'><ErrorMessage errors={errors} name="message" >
                                    {({ messages }) => {
                                        console.log(messages);
                                        return (
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message.message}</p>
                                            ))
                                        );
                                    }}
                                </ErrorMessage></p>
                            </div>
                            <button type='submit'>{lang[0] === 'en' ? en[3].submitBtn : sr[3].submitBtn}<i className="fa-solid fa-arrow-right"></i></button>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Contact;