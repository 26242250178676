import './WhyCard.scss';
import scrollRevealAnimation from '../../../../../../hooks/scrollRevealAnimation';
import { useEffect } from 'react';

function WhyCard(props) {
    const title = props.title;
    const ico = props.ico;
    const desc = props.desc;
 
    window.addEventListener("scroll", scrollRevealAnimation);
    
    return (
        <div className="why-card reveal">
            <div className='why-card-ico'>
                {/* <i className={`${ico}`}></i> */}
                <props.ico/>
                <h3>{props.title}</h3>
            </div>
            <div className='why-card-text'>
                <h3>{props.title}</h3>
                <p>{props.desc}</p>
            </div>
        </div>
    )
}
export default WhyCard;