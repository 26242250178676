import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Main from "./main/Main";
import Animation from "../../animation/Animation";
import { useEffect, useState } from "react";
import './Home.scss';


function Home() {

    let [showContent, setShowContent] = useState(false);
    let [opacityContent, setOpacityContent] = useState('content--hide');
    let content = '';
  
    useEffect(() => {
        let loadingTimeout = setTimeout(() => {
            setShowContent(true);
        }, 1500);
        
        let opacityContentTimeout = setTimeout(() => {
            setOpacityContent('content--show');
        }, 1550)

        return () => {
            clearTimeout(loadingTimeout);
            clearTimeout(opacityContentTimeout);
        }
      
    }, []);

    if (!showContent) {
        content = <div className="animation">
            <Animation />
        </div>
    } else {
        content =
            <div className={`home ${opacityContent}`}>
                <Header />
                <Main />
                <Footer />
            </div>
    }

    return content;
}
export default Home;