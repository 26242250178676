import Footer from '../../footer/Footer';
import Nav from "../../nav/Nav";
import './Privacy.scss';
import { useEffect, useState } from "react";
import Animation from "../../animation/Animation";
import en from '../../../assets/data/en/privacy.json';
import sr from '../../../assets/data/sr/privacy.json';
import { LangContext } from '../../../LangContext';
import { useContext } from 'react';

function Privacy() {
    let content = '';
    let [showContent, setShowContent] = useState(false);
    let [opacityContent, setOpacityContent] = useState('content--hide');

    let lang = useContext(LangContext);
    let privacyData = [];
    lang[0] === 'en' ? privacyData = en : privacyData = sr;

    let privacyDataJsx = privacyData.map(function (item, i) {
        const text = item.text;
        let messages = [];
        text.forEach((el) => {
            messages.push(el.val);
        })
     
        return (
            <div className='privacy-info-block' key={i}>
                <h2>{item.title}</h2>
                {
                        messages.map(function(message,i){
                            return <p>{message}</p>
                        })
                    }
            </div>
        )
    });

    useEffect(() => {
        let loadingTimeout = setTimeout(() => {
            setShowContent(true);
        }, 1500);

        let opacityContentTimeout = setTimeout(() => {
            setOpacityContent('content--show');
        }, 1600)

        return () => {
            clearTimeout(loadingTimeout);
            clearTimeout(opacityContentTimeout);
        }
    }, []);

    if (!showContent) {
        content = <div className="animation">
            <Animation />
        </div>
    }
    else {
        content =
            <div className={`privacy ${opacityContent}`}>
                <Nav />
                <div className='privacy-info wrapper'>
                    {privacyDataJsx}
                    {/* <div className='privacy-info-block'>
                <h2>The types of personality data we collect</h2>
                <p>krug.is.rs collects only basic and necessary data on users of the site and statistical data necessary for the analysis of the use and traffic of the site in order to improve the technical capabilities of the site in order to better inform our users.</p>
                <p>The data we collect and process is a data you write in our contact form and the IP address. We share information about how you use the site with reliable partners who run social networks and analytics, in order to better place the content we publish based on them. If you continue to use the krug.is.rs website, you agree to the use of our cookies.</p>
                <p>Cookies are simple text files that websites use to make their use of the site more efficient for users. Cookies are stored in a web browser while a user browses a website.</p>
                <p>The site krug.is.rs uses different types of cookies, some of which are necessary for the proper functioning of the site and display of content, and others are used for the purpose of analyzing how to use the site. Most cookies collect anonymous data.</p>
                <p>The first time you visit the site, a notification about the use of cookies will pop up. All cookies are classified into the following categories: necessary, to improve the site, marketing, statistics and more. It is not possible to search and use our site without the necessary cookies. For all other cookies, you will have the option of accepting their use or not. If you do not agree to use such cookies, you will still be able to use the site, but the functionality of use may be reduced.</p>
                <p>The law states that we may store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your consent.</p>
            </div>
            <div className='privacy-info-block'>
                <h2>Purpose of processing and legal basis</h2>
                <p>krug.is.rs uses your data for the following purposes:</p>
                <p>– Customer support,</p>
                <p>– Functioning of the site,</p>
                <p>– Informing users,</p>
                <p>– Site traffic analysis</p>
                <p>krug.is.rs processes personal data on the basis of its own legitimate interest and exclusively for the purpose of enabling easier searching of the krug.is.rs site and analysis of the use and visit to the site.</p>
                <p>All user data is strictly kept and is available only to employees who need this data to do their job and they are responsible for respecting the principles of privacy.</p>
            </div>
            <div className='privacy-info-block'>
                <h2>Data users and Data protection</h2>
                <p>Only employees in the IT sector, the person in charge of communications and the person in charge of maintaining accounts on social networks have access to some of your data. Also, state bodies may be provided with access to data on the basis of a legal request.</p>
                <p>krug.is.rs uses antivirus protection, undertakes to keep all data of its users, and will not keep data longer than necessary.</p>
            </div>
            <div className='privacy-info-block'>
                <h2>Citizens’ rights</h2>
                <p>You have the following rights regarding the processing of your personal data:</p>
                <p>– Confirmation of whether we are processing your personal data;</p>
                <p>– Access to your personal data;</p>
                <p>– Correction of your personal data in case of error;</p>
                <p>– Deleting your personal data;</p>
                <p>– Interruption of the processing of your personal data;</p>
                <p>– Refusal to have your personal data processed;</p>
                <p>– Transfer of personal data to another service provider;</p>
                <p>In the case of automated processing of personal data and decision-making, the right to human intervention, expression of opinion on the decision or challenge the decision.</p>
                <p>You can exercise your rights by contacting krug.is.rs in the following ways:</p>
                <p>Email: support@krug.is.rs</p>
            </div>
            <div className='privacy-info-block'>
                <h2>Supervisor</h2>
                <p>The competent body for the protection of personal data is the Commissioner for Information of Public Importance and Personal Data Protection of the Republic of Serbia. You can contact the authority at the address Bulevar Kralja Aleksandra 15, 11000 Belgrade, Republic of Serbia, by email at office@poverenik.rs or by phone at +381 11 3408 900.</p>
            </div> */}
                </div>
                <Footer />
            </div>

    }
    return content;



}
export default Privacy;