import './Description.scss';
import Title from '../../../../../common/title/Title';
import Dash2 from '../../../../../../assets/images/dash2.webp';
import scrollRevealAnimation from '../../../../../../hooks/scrollRevealAnimation';

function Description(props) {

    const flexType = props.flexType;
    const revealFromImg = props.revealFromImg;
    const revealFromDesc = props.revealFromDesc;

    window.addEventListener("scroll", scrollRevealAnimation);
    const list = props.list.map(function (listItem, i) {

        return (
            <li className='description-block-list-item' key={i}>
                <i className="fa-solid fa-check"></i>
                <p>{listItem.text}</p>
            </li>
        )
    })
    return (
        <div className={`description ${flexType}`}>
            <img className={`description-img reveal ${revealFromImg}`} src={Dash2} alt='' />
            <div className={`description-block reveal ${revealFromDesc}`}>
                <Title
                    subtitle={props.subtitle}
                    title={props.title}
                    modeType='dark'
                />
                <p className='description-block-text'>{props.desc}</p>
                <ul className='description-block-list'>

                    {list}
                    {/* <li className='description-block-list-item'>
                        <i className="fa-solid fa-check"></i>
                        <p></p>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default Description;