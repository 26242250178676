
import './Screenshots.scss';
import Title from '../../../../common/title/Title';
import HeaderImg from '../../../../../assets/images/dash1.webp';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import scrollRevealAnimation from '../../../../../hooks/scrollRevealAnimation';
import en from '../../../../../assets/data/en/screenshot.json';
import sr from '../../../../../assets/data/sr/screnshot.json';
import { LangContext } from '../../../../../LangContext';
import { useContext } from 'react';

function Screenshots() {

    let lang = useContext(LangContext);
    window.addEventListener("scroll", scrollRevealAnimation);

    const responsive = {
        desktop3: {
            breakpoint: { max: 4000, min: 2560 },
            items: 3
        },
        desktop2: {
            breakpoint: { max: 2560, min: 1920 },
            items: 2
        },

        desktop1: {
            breakpoint: { max: 1920, min: 1440 },
            items: 1
        },
        bigTablet: {
            breakpoint: { max: 1440, min: 768 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1
        },

        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className='screenshots' id='dashboard-screenshots'>
            <div className='wrapper'>
                <Title subtitle={lang[0] === 'en' ? en.header.subtitle : sr.header.subtitle} title={lang[0] === 'en' ? en.header.title : sr.header.title} modeType='dark' />
                <div className='images-slider reveal reveal-from-left'>
                    <Carousel
                        responsive={responsive}
                        containerClass="carousel-container-imgs"
                        itemClass="carousel-item" swipeable={true} showDots={false} infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={4000}
                        customTransition="all .7s ease"
                        transitionDuration={700}
                        keyBoardControl={true}
                    >
                        <img src={HeaderImg} />
                        <img src={HeaderImg} />
                        <img src={HeaderImg} />
                        <img src={HeaderImg} />
                        <img src={HeaderImg} />

                        <img src={HeaderImg} />
                    </Carousel>
                </div>
            </div>

        </div>
    )
}

export default Screenshots;