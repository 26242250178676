import React, { Fragment, useEffect, useState, useRef } from 'react';
import Logo from '../../assets/images/logo_krug.png';
import './Nav.scss';
import KSVG from '../../assets/images/k.svg';
import RSVG from '../../assets/images/R.svg';
import USVG from '../../assets/images/U.svg';
import GSVG from '../../assets/images/G.svg';
import { NavHashLink } from 'react-router-hash-link';
import useWindowDimensions from '../../hooks/windowDimension';
import { RiHomeLine } from 'react-icons/ri';
import { AiOutlineMail } from 'react-icons/ai';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoImagesOutline } from 'react-icons/io5';
import { FiPackage } from 'react-icons/fi';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FiPieChart } from 'react-icons/fi';
// import navData from '../../assets/data/nav.json';
import { LangContext } from '../../LangContext';
import { useContext } from 'react';
import en from '../../assets/data/en/nav.json';
import sr from '../../assets/data/sr/nav.json';

function Nav(props) {

    const { height, width } = useWindowDimensions();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [active, setActive] = useState(1);
    const [navStatus, setNavStatus] = useState(false);
    const [preload, setPreload] = useState(true);
    let [lang, setLang] = useContext(LangContext);
    const [langActive, setLangActive] = useState(false);

    const enRef = useRef();
    const srRef = useRef();

    let navData = [];

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    const openNav = () => {
        setNavStatus(true);
    }
    const closeNav = () => {
        setTimeout(() => {
            setNavStatus(false);
        }, 100)
    }


    const icons = [RiHomeLine, FiPieChart, AiOutlineDashboard, FiPackage, IoImagesOutline, AiOutlineQuestionCircle, AiOutlineMail]

    lang === 'en' ? navData = en : navData = sr;

    const navDataJsxDesktop = navData.map(function (navItem, i) {
        return (
            <NavHashLink
                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                to={navItem.href} onClick={() => { setActive(navItem.id) }}
                key={i}
            >
                <li className={`nav-link ${active === navItem.id ? 'nav-link--active' : ''}`}>{navItem.text}<div className='line'></div></li>
            </NavHashLink>
        )
    });

    const navDataJsxResponsive = navData.map(function (navItem, i) {
        const CustomIcoTaq = icons[i];
        return (

            <li className="nav-link" key={i}>
                <NavHashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} to={navItem.href} onClick={closeNav}><CustomIcoTaq />
                    <span>{navItem.text}</span>
                </NavHashLink>
            </li>
        )
    })

    const navLogoImage = (
        <NavHashLink to='/'>
            <div className='nav-logo'>
                <img src={Logo} alt="KRUG Logo" className='nav-logo-image' />

                <div className='nav-logo-desc logo-text'>
                    <img src={KSVG} className='k letter' />
                    <img src={RSVG} className='r letter' />
                    <img src={USVG} className='u letter' />
                    <img src={GSVG} className='g letter' />
                </div>
            </div>
        </NavHashLink>
    );

    useEffect(() => {

        window.addEventListener('scroll', handleScroll, { passive: true });
    
        lang === 'en' ? enRef.current.classList.add('active') : enRef.current.classList.remove('active')
        lang === 'sr' ? srRef.current.classList.add('active') : srRef.current.classList.remove('active')

        let preloadTimeout = setTimeout(() => {
            setPreload(false)
        }, 1300)

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(preloadTimeout);
        };

    }, [lang]);

    return (
        <Fragment>
            <div className={`nav-langs-overlay ${langActive ? 'nav-langs-overlay--show' : 'nav-langs-overlay--hide'}`} onClick={() => { setLangActive(!langActive); }}>

            </div>
            <nav>
                <div className={`nav-wrapper ${width > 1024 ? 'show' : 'hide'} ${scrollPosition > 30 ? `nav-bcg--show` : `nav-bcg--hide`} `}>
                    {navLogoImage}
                    <ul className={`nav-links`}>
                        {navDataJsxDesktop}
                        <div className='nav-langs nav-link'>
                            <div className='nav-langs-active'>
                                <p onClick={() => setLangActive(!langActive)}>{lang}</p>
                            </div>
                            <div className={`nav-langs-inactive ${langActive ? 'nav-langs-inactive--show' : 'nav-langs-inactive--hide'}`}>
                                <p onClick={() => { setLang('sr'); setLangActive(!langActive) }}>sr</p>
                                <p onClick={() => { setLang('en'); setLangActive(!langActive) }}>en</p>
                            </div>
                        </div>
                    </ul>
                </div>
                <div className={`nav-wrapper-responsive ${width <= 1024 ? 'show' : 'hide'}`}>
                    <div className={`nav-wrapper-responsive-header  ${scrollPosition > 30 ? `nav-bcg--show` : `nav-bcg--hide`} ${navStatus ? 'setWhiteBackground' : ''}`}>
                        {navLogoImage}
                
                        <div className='nav-wrapper-responsive-header-icons'>
                            <div className={`nav-langs`}>
                              
                                <p onClick={() => { setLang('sr'); }}  ref={srRef}>sr</p>
                                <p onClick={() => { setLang('en'); }}  ref={enRef}>en</p>
                            </div>
                            <ul className={`nav-links`}>
                                <i className={`ico ${navStatus ? 'fa-regular fa-circle-xmark' : 'fa-solid fa-bars'}`} onClick={navStatus ? closeNav : openNav}></i>
                            </ul>
                        </div>

                    </div>
                    <div className={`nav-wrapper-responsive-body  ${navStatus === true ? 'nav-responsive--show' : 'nav-responsive--hide'} ${preload ? 'preload' : ''}`}>
                        <div className='nav-responsive-links'>
                            {navDataJsxResponsive}
                        </div>
                    </div>
                    <div className={`${navStatus ? 'hiddenCloseNav' : 'hideHiddenCloseNav'}`} onClick={closeNav}>

                    </div>
                </div>
            </nav>
        </Fragment >

    )
}
export default Nav;