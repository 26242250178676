import './Footer.scss';
import Logo from '../../assets/images/logo_krug.png';
import { Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import { useState } from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import { BsTelephone } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import enFooter from '../../assets/data/en/footer.json';
import srFooter from '../../assets/data/sr/footer.json';
import enNav from '../../assets/data/en/nav.json';
import srNav from '../../assets/data/sr/nav.json';
import enContact from '../../assets/data/en/contact.json';
import srContact from '../../assets/data/sr/contact.json';
import { LangContext } from '../../LangContext';
import { useContext } from 'react';

function Footer() {

    const [active, setActive] = useState(1);
    let lang = useContext(LangContext);
    let navData = [];
    let contactData = [];

    lang[0] === 'en' ? navData = enNav : navData = srNav;
    lang[0] === 'en' ? contactData = enContact[1].contactData : contactData = srContact[1].contactData;

    const navFooterDataJsx = navData.map(function (navItem, i) {
        return (
            <NavHashLink
                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                to={navItem.href} onClick={() => { setActive(navItem.id) }}
                key={i}
            >
                <li className='nav-foo'>{navItem.text}</li>
            </NavHashLink>
        )
    });

    const icons = [MdOutlineEmail, BsTelephone, GoLocation];

    const contactFooterDataJsx = contactData.map(function (contactData, i) {
        const CustomIcoContactBlock = icons[i];
        return (
            <li key={i}>
                <CustomIcoContactBlock />
                <a href={contactData.val} target='_blank'>{contactData.text}</a>
            </li>
        )
    })

    return (
        <div className="footer">
            <div className="wrapper">
                <div className='footer-blocks'>
                    <div className='footer-block footer-about'>

                        <Link to='/' className='footer-block-title'>
                            <img src={Logo} alt="KRUG Logo" />
                            KRUG
                        </Link>
                        <p>{lang[0] === 'en' ? enFooter[0].text : srFooter[0].text}</p>
                    </div>
                    <div className='footer-block'>
                        <h4 className='footer-block-title'>{lang[0] === 'en' ? enFooter[1].titles[0].title : srFooter[1].titles[0].title}</h4>
                        <ul>
                            {navFooterDataJsx}
                        </ul>
                    </div>

                    <div className='footer-block'>
                    <h4 className='footer-block-title'>{lang[0] === 'en' ? enFooter[1].titles[1].title : srFooter[1].titles[1].title}</h4>
                        <ul>
                            {contactFooterDataJsx}
                        </ul>
                    </div>
                    <div className='footer-block'>
                    <h4 className='footer-block-title'>{lang[0] === 'en' ? enFooter[1].titles[2].title : srFooter[1].titles[2].title}</h4>

                        <ul>
                            <li>
                                <Link to='privacy-policy'>{lang[0] === 'en' ? enFooter[1].titles[2].links[0].text : srFooter[1].titles[2].links[0].text}</Link>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className='copy'>
                    <p>&copy; 2022 KRUG. {lang[0] === 'en' ? enFooter[2].copy : srFooter[2].copy}</p>
                </div>
            </div>
        </div>
    )
}
export default Footer;