
import './App.scss';
import {
  // BrowserRouter as Router,
  Route,
  Routes,
  HashRouter as Router
} from "react-router-dom";
import Home from './components/pages/home/Home';
import Privacy from './components/pages/privacy/Privacy';
import NotFound from './components/pages/not-found/NotFound';
import ScrollToTop from './hooks/scrollToTop';
import { useContext, useEffect, useState } from 'react';
import { LangContext } from './LangContext';
import ReactGA from 'react-ga';

function App() {

  let [context,setContext] = useState(localStorage.getItem('lang') === null ? 'en' : localStorage.getItem('lang'));

  localStorage.setItem('lang',context);

  ReactGA.initialize('G-SF73303NFP');
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {

  }, [])

  return (
    <Router basename='/' hashType='noslash' >
      <ScrollToTop>
        <Routes>

          <Route exact path='/' element={<LangContext.Provider value={[context, setContext]}><Home />  </LangContext.Provider>} />
          <Route exact path='/privacy-policy' element={<LangContext.Provider value={[context, setContext]}><Privacy /></LangContext.Provider>} />
          <Route path='*' element={<NotFound />} />

        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
