import './Question.scss';

function Question(props) {
    return (
        <div className='question-block '>
            <p className='num'>{props.num}</p>
            <p className='question'>{props.question}</p>
            <p className='answer'>{props.answer}</p>
        </div>

    )
}
export default Question;